import React from 'react';

export default function LeadParagraph({ children, center }) {
  return (
    <div
      className={`max-w-2xl tracking-wider md:text-xl ${
        center ? 'text-center mx-auto' : ''
      }`}>
      {children}
    </div>
  );
}
