import * as React from 'react';

import Layout from '../components/layout/layout';
import Seo from '../components/seo/seo';
import HomeHero from '../components/home/homeHero';
import Greeting from '../components/home/greeting';
import Desarrollos from '../components/home/desarrollos';

const IndexPage = () => (
  <Layout>
    <div className="Polka">
      <Seo title="Home" />
      <HomeHero />
      <Greeting />
      <Desarrollos />
    </div>
  </Layout>
);

export default IndexPage;
