import React from 'react';

export default function Button({ to, color, Icon, text }) {
  return (
    <a href={to} className="w-max inline-block">
      <div className="flex items-center bg-gradient-to-r from-green-800 to-green-900 px-6 py-4 rounded-sm mt-6 hover:shadow-xl transition">
        <p className="m-0 md:text-xl mr-6 text-white">{text}</p>
        <Icon className="text-white text-xl animate-bounce" />
      </div>
    </a>
  );
}
