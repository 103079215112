import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import LeadParagraph from '../typography/leadParagraph';
import DesarrollosCards from '../UI/desarrollosCards';

export default function Desarrollos() {
  return (
    <div className="py-16 md:py-30 px-2" id="desarrollos">
      <LeadParagraph center>
        <StaticImage
          src="../../images/NH_logo_h-short.png"
          alt="NH Logo"
          className="w-24"
        />
        <p>
          Northouses presenta 2 desarrollos significativos para la ciudad y
          beneficios para sus potenciales propietarios.
        </p>
        <p>
          A solo 10 km del centro de la ciudad de Formosa un diseño urbanístico
          único se complementa con la naturaleza.
        </p>
        <p className="font-serif text-3xl text-green-900">
          "Un lugar ideal para vivir y<br />
          disfrutar con amigos"
        </p>
      </LeadParagraph>
      <DesarrollosCards />
    </div>
  );
}
