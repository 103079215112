import React, { useState } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

export default function DesarrolloCard({ title, logoDark, logoLight, page }) {
  const [mouseOver, setMouseOver] = useState(false);

  return (
    <Link to={page}>
      <div
        onMouseEnter={() => setMouseOver(true)}
        onMouseLeave={() => setMouseOver(false)}
        role="button"
        tabIndex="0"
        className="w-60 h-60 m-6 rounded-md overflow-hidden transition transform hover:scale-105 hover:shadow-lg">
        {mouseOver ? (
          <GatsbyImage
            image={logoDark}
            alt={title}
            className="pointer-events-none"
            loading="eager"
          />
        ) : (
          <GatsbyImage
            image={logoLight}
            alt={title}
            className="pointer-events-none"
            loading="eager"
          />
        )}
      </div>
    </Link>
  );
}
