import React from 'react';
import { useSpring, a, config } from 'react-spring';

import bgVideo from '../../video/villa_aerial_view_low.mp4';

export default function HomeHero() {
  // SPRINGS

  /**
   * Animates the green block behind the title
   */
  const scaleSpring = useSpring({
    to: { scaleX: 1 },
    from: { scaleX: 0 },
  });

  /**
   * Fades in the the title from the bottom after the green div appears
   */
  const fadeInSpring = useSpring({
    to: { y: 0, opacity: 1 },
    from: { y: 20, opacity: 0 },
    delay: 400,
    config: config.slow,
  });

  /**
   * Fades in the white line that indicates scrollable content
   */
  const slideDownSpring = useSpring({
    to: { y: 0, opacity: 1 },
    from: { y: 100, opacity: 0 },
    config: config.slow,
    delay: 800,
  });

  return (
    <div className="h-screen bg-gradient-to-b from-green-900 via-green-800 to-green-900 relative overflow-hidden flex items-center justify-center">
      <video
        autoPlay
        muted
        loop
        className="absolute inset-0 w-screen h-screen filter blur object-cover">
        <source src={bgVideo} type="video/mp4" />
      </video>

      {/* Overlay */}
      <div className="absolute inset-0 h-full w-full bg-black opacity-20"></div>

      {/* Title */}
      <div className="absolute inset-0 w-full h-full flex items-center justify-center">
        <a.div
          style={scaleSpring}
          className="bg-green-900 h-32 w-11/12 max-w-[1000px] rounded-sm"></a.div>
        <div className="absolute text-white text-center">
          <a.h1 className="text-6xl lg:text-9xl" style={fadeInSpring}>
            Invertí en <br />
            calidad de vida
          </a.h1>
        </div>
      </div>

      {/* Just a line */}
      <a.div
        style={slideDownSpring}
        className="w-[2px] h-1/4 absolute bottom-0 bg-white"></a.div>
    </div>
  );
}
