import React from 'react';
import { BsBoxArrowInDown } from 'react-icons/bs';

import Button from '../UI/button';
import LeadParagraph from '../typography/leadParagraph';

export default function Greeting() {
  return (
    <div className="py-16 md:py-30 w-11/12 mx-auto flex flex-col lg:flex-row px-2">
      <div className="md:w-1/3 mb-6">
        <h2 className="text-6xl m-0 font-bold">Hola!</h2>
        <div className="bg-green-900 h-2 w-24 mt-3 ml-1 shadow-sm"></div>
      </div>
      <div>
        <LeadParagraph>
          <p>
            Somos NH, una empresa joven que se abre paso en la ciudad, creciendo
            y ampliando su cartera de productos y servicios.
          </p>
          <p>Queremos llegar a todos quienes deseen invertir! </p>
          <p>
            Tenemos el objetivo de integrarnos a través de distintas unidades de
            negocio: <br />
            <span className="font-bold">
              Desarrollo inmobiliario, Proyectos y Negocios.
            </span>
          </p>
        </LeadParagraph>
        <Button
          Icon={BsBoxArrowInDown}
          color="dark"
          to="#desarrollos"
          text="Conozca nuestros proyectos"
        />
      </div>
    </div>
  );
}
