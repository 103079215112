import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';

import DesarrolloCard from './desarrolloCard';

import { desarrollos } from '../../data/desarrollos.json';

export default function DesarrollosCards() {
  const imgData = useStaticQuery(graphql`
    query MyQuery {
      allFile {
        edges {
          node {
            id
            relativePath
            childImageSharp {
              gatsbyImageData(
                width: 300
                formats: [AUTO, WEBP, AVIF]
                placeholder: TRACED_SVG
              )
            }
          }
        }
      }
    }
  `);

  // Returns a proper img object for the image prop in GatsbyImage
  const getLogo = (type, desarrollo) => {
    // Extract the image file from the query
    const logoFile = imgData.allFile.edges.find(
      (image) => image.node.relativePath === desarrollo.logo[type]
    );

    console.log('getLogo');

    return getImage(logoFile.node);
  };

  return (
    <div className="w-11/12 mx-auto flex justify-center flex-wrap">
      {desarrollos.map((desarrollo) => (
        <DesarrolloCard
          key={desarrollo.id}
          title={desarrollo.title}
          page={desarrollo.link}
          logoDark={getLogo('dark', desarrollo)}
          logoLight={getLogo('light', desarrollo)}
        />
      ))}
    </div>
  );
}
